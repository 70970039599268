import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PickStaff = (props) => {
  let params = useParams();
  let navigate = useNavigate();

  const finalizeBooking = async (event) => {
    event.stopPropagation();
    const formData = {
      booked_services: JSON.stringify(props.selectedServices),
      customer_id: params.customerId,
      staff_id: event.target.getAttribute("data-id"),
    };

    await axios
      .post(props.apiURLNext + "employees/finalize_walkin_booking", formData)
      .then((response) => {
        console.log(response);
        if (response.data.code === 200) {
          navigate("/final_screen");
        } else {
          toast.error(
            "Something went wrong! Please ask for help at reception!",
            {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      })
      .catch((error) => {
        console.log("Error ========>", error);
      });
  };

  return (
    <div className="appContainer__pickStaffs">
      <Typography
        style={{ textAlign: "center" }}
        mt={3}
        variant="h5"
        gutterBottom
        component="div"
      >
        Please choose any staff to do your service
      </Typography>
      <div className="booking-card-container staff-container">
        {props.availableStaffs.map((staffRow) => (
          <div
            data-id={staffRow.id}
            data-name={staffRow.staff_name}
            onClick={finalizeBooking}
            key={staffRow.id}
            className="booking-card-item staff-item"
          >
            <img
              data-id={staffRow.id}
              data-name={staffRow.staff_name}
              onClick={finalizeBooking}
              src={`${props.apiURL}assets/images/staff_avatars/${staffRow.avatar}`}
              alt=""
            />
            <span
              className="staff-name"
              data-id={staffRow.id}
              data-name={staffRow.staff_name}
              onClick={finalizeBooking}
            >
              {staffRow.staff_name}
            </span>
            <span>
              <ArrowForwardIosIcon />
            </span>
          </div>
        ))}
      </div>
      <ButtonGroup
        variant="contained"
        style={{
          margin: "15px 0",
          display: "flex",
          flexFlow: "column",
          gap: "15px",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ padding: "15px" }}
          className="btn-success"
          onClick={(e) => {
            navigate("/pick_services/" + params.customerId);
          }}
          startIcon={<ArrowBackIosNewIcon />}
        >
          Back to Service List
        </Button>
        <Button
          style={{ padding: "15px" }}
          color="error"
          onClick={(e) => {
            window.location.href = "/";
          }}
          endIcon={<ArrowForwardIosIcon />}
        >
          Exit Now
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default PickStaff;
